import { Grid } from '@material-ui/core';
import React from 'react';
import { Text, CompactButton, Colors } from 'library';
import { format12HourTime } from 'shared/Dates';
import CardInfo from './CardInfo';
import { useHistory } from 'react-router-dom';
import { IHourReport } from 'internal/payments/HourReportModels';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

export default function HourReportInfo({ hourReport, timezone }: { hourReport: IHourReport; timezone: string }) {
    const history = useHistory();

    return (
        <>
            <Grid container item direction="row">
                <Grid item xs={12}>
                    {hourReport.start ? (
                        <CardInfo
                            title="Clocked Hours:"
                            content={`${format12HourTime(hourReport.start, timezone)}${hourReport.end ? ` - ${format12HourTime(hourReport.end, timezone)}` : ''}${hourReport.approved_break ? ` (${hourReport.approved_break} min break)` : ''}`}
                        />
                    ) : hourReport.late_minutes ? (
                        <Text
                            variant="body1"
                            bold
                            textStyle={{ color: Colors.mediumRed }}
                        >{`Running ${hourReport.late_minutes} minutes late`}</Text>
                    ) : null}
                    <Text variant="caption">{`${hourReport.hours_worked ? `${hourReport.hours_worked.toString()} hours worked` : ''}`}</Text>
                </Grid>
            </Grid>
            <Grid container item direction="row">
                <Grid item xs={6}>
                    {hourReport.start && hourReport.end && !hourReport.paid ? (
                        <CardInfo title="Status:" content={'Pending'} />
                    ) : null}
                </Grid>
            </Grid>
            {hourReport.start && hourReport.end && !hourReport.paid ? (
                <Grid item direction="column" style={{ marginTop: 5 }}>
                    <CompactButton onClick={() => history.push('confirm-hours')}>
                        {' '}
                        <WatchLaterIcon style={{ fontSize: 18 }} />
                        Edit clocked hours
                    </CompactButton>
                </Grid>
            ) : null}
        </>
    );
}
