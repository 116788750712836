import React from 'react';
import theme from 'theme';
import { AppBar, styled } from '@mui/material';
import { Colors, IconButton } from 'library';
import { Redeem, Menu } from '@mui/icons-material';

import ImpersonationButton from '../ImpersonationButton';
import { ProfilePicInNav } from '../AccountMenu/ProfilePicInNav';

export function NavigationBar({
    small,
    open,
    setOpen,
}: {
    small: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    return (
        <StyledAppBar position="fixed">
            <StyledBar small={small.toString()}>
                <Section>
                    <IconButton
                        onClick={() => setOpen(!open)}
                        icon={Menu}
                        id="btnDrawer"
                        aria-label="open drawer"
                        backgroundColor="transparent"
                        color={Colors.white}
                        style={{ boxShadow: 'none', marginRight: small ? 0 : 20 }}
                        size={40}
                    />
                    <ImpersonationButton />
                </Section>
                <Section>
                    <IconButton
                        icon={Redeem}
                        id="btnRefer"
                        aria-label="refer another center"
                        backgroundColor="transparent"
                        color={Colors.white}
                        style={{ boxShadow: 'none', marginRight: small ? 10 : 20 }}
                        size={40}
                    />
                    <ProfilePicInNav />
                </Section>
            </StyledBar>
        </StyledAppBar>
    );
}

const StyledAppBar = styled(AppBar)({
    boxShadow: 'none',
    backgroundColor: Colors.darkNavy,
});

const StyledBar = styled('div')(({ small }: { small: string }) => ({
    alignItems: 'center',
    backgroundColor: Colors.darkNavy,
    display: 'flex',
    height: small === 'true' ? 50 : 60,
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: 10,
    width: '100vw',
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

const Section = styled('div')({
    alignItems: 'center',
    display: 'flex',
    width: 'fit-content',
});
