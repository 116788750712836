import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { Colors, CompactButton, Text } from 'library';
import { format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';
import { IBusinessJobDetailsModel } from '../../BusinessJob/models';
import { CalendarColors } from '../HiredShift';
import ProviderDisplay from './ProviderDisplay';
import CardInfo from './CardInfo';
import HourReportInfo from './HourReportInfo';
import ShiftCardMenu from './ShiftCardMenu';
import { isAfter, startOfTomorrow, isSameDay, addMinutes } from 'date-fns';
import RemoveProviderFromShfit from './RemoveProviderFromShfit';
import ActionRequired from './ActionRequired';
import { useHistory } from 'react-router';
import BanProviderAction from './BanProviderAction';
import CancelShiftModalTrigger from './CancelShiftModalTrigger';
import { BusinessSlot, ShiftJob, ShiftProviderUser } from 'models';
import MarkNoShowButton from './MarkNoShowButton';
import ClockInButton from './ClockInButton';
import CardRequestedTimeChange from './CardRequestedTimeChange';
import { getShiftTimes } from './shiftTimesUtils';
import ComplianceActions from './ComplianceActions';
import StarIcon from '@mui/icons-material/Star';

export default function ShiftBaseCard({
    provider,
    pay,
    status,
    provider_id,
    ongoing_request,
    slot,
    refresh,
    provider_action_required,
    review_score,
    needs_center_action,
}: {
    provider?: ShiftProviderUser;
    pay?: number | null;
    status: string;
    provider_id: number;
    slot: BusinessSlot;
    ongoing_request: IBusinessJobDetailsModel | ShiftJob;
    provider_action_required?: boolean;
    refresh: () => void;
    review_score?: number;
    needs_center_action?: string[];
}) {
    const history = useHistory();
    const shift = slot.appointments.length ? slot.appointments[0] : undefined;
    const hourReport = shift?.hour_reports?.find((r) => r.provider.id === provider_id);
    const [banModal, setBanModal] = useState(false);
    const { shiftStart, shiftEnd, infoText } = getShiftTimes(slot);

    const clockedIn = hourReport && hourReport.start && !hourReport.end;

    const clockedInStyle = clockedIn ? { border: 'solid', borderWidth: 2, borderColor: Colors.darkNavy } : {};

    const showRemoveProvider =
        ['APPROVED', 'ON_STAFF'].includes(status) && isAfter(new Date(slot.start_date), startOfTomorrow());

    const completed = status === 'COMPLETED';

    const shouldRenderCancelShiftTrigger =
        (showRemoveProvider || isSameDay(new Date(slot.start_date), new Date())) && !hourReport?.start;

    function renderManageShiftSection() {
        const showManageShiftActions =
            !hourReport?.start &&
            isAfter(new Date(), new Date(slot.start_date)) &&
            isSameDay(new Date(slot.start_date), new Date());

        if (!showManageShiftActions) {
            return;
        }

        const shiftStart = new Date(slot.start_date);
        const startPlusThirty = addMinutes(shiftStart, 30);
        const isThirtyMinAfterStart = isAfter(new Date(), startPlusThirty);

        return (
            <Grid container item direction="row" spacing={1} style={{ marginTop: 8 }}>
                {isThirtyMinAfterStart && shift ? (
                    <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <MarkNoShowButton
                            shift={shift}
                            provider={provider}
                            onComplete={refresh}
                            providerId={provider_id}
                        />
                    </Grid>
                ) : null}
                {shift && (
                    <Grid item style={{ whiteSpace: 'nowrap' }}>
                        <ClockInButton
                            appointmentId={shift.id}
                            startDate={shift.start_date}
                            timezone={ongoing_request.timezone}
                            providerName={provider?.first_name || 'Worker'}
                            providerUserId={provider?.id || 0}
                            onComplete={refresh}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }

    function PostShiftHeader() {
        const hasClockOut = !!hourReport?.end || true;
        if (!completed && !hasClockOut) {
            return null;
        }

        return (
            <Grid item style={{ whiteSpace: 'nowrap', marginTop: 5 }}>
                <ReviewAction />
            </Grid>
        );
    }

    function ReviewAction() {
        if (!completed) {
            return null;
        }

        return review_score ? (
            <Rating value={review_score} disabled={true} />
        ) : (
            <CompactButton onClick={() => history.push(`/providers/review/${provider?.id}`)}>
                <StarIcon style={{ fontSize: 18 }} />
                Leave a review
            </CompactButton>
        );
    }

    const businessLocationId =
        'address' in ongoing_request
            ? ongoing_request?.address?.business_location
            : ongoing_request.business_location.id;

    return (
        <Grid item container direction="column" alignContent="center">
            <Grid
                item
                container
                style={{
                    borderRadius: 18,
                    backgroundColor: completed
                        ? Colors.lightGrey
                        : CalendarColors[ongoing_request.business_job_type ?? ''] ?? Colors.lightTurq,
                    padding: 10,
                    paddingBottom: 15,
                    boxShadow: '0px 3px 6px 0px #00000029',
                    ...clockedInStyle,
                    marginBottom: 5,
                }}
            >
                <Grid
                    container
                    item
                    direction="row"
                    alignItems="flex-start"
                    style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                    }}
                >
                    <Grid
                        xs={10}
                        container
                        item
                        direction="column"
                        alignContent="flex-start"
                        style={{ marginBottom: 8 }}
                    >
                        {provider ? (
                            <ProviderDisplay provider={provider} />
                        ) : (
                            <Text bold>{formatShortWeekdayMonthDay(slot.start_date)}</Text>
                        )}
                    </Grid>

                    <Grid
                        xs={2}
                        container
                        item
                        direction="row"
                        alignItems="center"
                        justify="flex-end"
                        style={{ paddingRight: 10 }}
                    >
                        <ShiftCardMenu
                            provider_id={provider_id}
                            provider={provider}
                            slot={slot}
                            ongoing_request={ongoing_request}
                            status={status}
                            refresh={refresh}
                            hourReport={hourReport}
                        />
                    </Grid>
                    <ComplianceActions
                        needs_center_action={needs_center_action}
                        business_location_id={businessLocationId}
                        refresh={refresh}
                        provider_user={provider}
                        has_completed_shift={completed}
                    />
                </Grid>
                <Grid container item style={{ paddingLeft: 8, gap: 6 }}>
                    {shift?.center_initiated_appointment_time_change_request && (
                        <CardRequestedTimeChange
                            type="center"
                            timeChangeRequest={shift.center_initiated_appointment_time_change_request}
                            refresh={refresh}
                            timezone={ongoing_request.timezone}
                        />
                    )}
                    {shift?.worker_initiated_appointment_time_change_request && (
                        <CardRequestedTimeChange
                            type="worker"
                            timeChangeRequest={shift.worker_initiated_appointment_time_change_request}
                            refresh={refresh}
                            timezone={ongoing_request.timezone}
                        />
                    )}
                    <Grid container item direction="row">
                        <Grid item xs={10}>
                            <CardInfo
                                title={ongoing_request.headline}
                                content={`${format12HourTime(shiftStart, ongoing_request.timezone)} - ${format12HourTime(shiftEnd, ongoing_request.timezone, true)}`}
                                infoText={infoText}
                                bodyTextBold={false}
                                titleTextBold={true}
                            />
                        </Grid>
                        <Grid item>{pay ? <CardInfo bodyTextBold={false} content={`$${pay}/hr`} /> : null}</Grid>
                    </Grid>
                    {hourReport && provider ? (
                        <HourReportInfo hourReport={hourReport} timezone={ongoing_request.timezone} />
                    ) : null}
                </Grid>
                {provider_action_required ? <ActionRequired /> : null}
                {renderManageShiftSection()}
                <PostShiftHeader />
                <Grid container item direction="row" spacing={1} style={{ marginTop: 8 }}>
                    {showRemoveProvider && provider && shift ? (
                        <Grid item style={{ whiteSpace: 'nowrap' }}>
                            <RemoveProviderFromShfit
                                shift={shift}
                                provider={provider}
                                provider_id={provider_id}
                                setBanModal={setBanModal}
                            />
                            <BanProviderAction
                                provider={provider}
                                open={banModal}
                                onClose={() => {
                                    setBanModal(false);
                                    refresh();
                                }}
                            />
                        </Grid>
                    ) : null}

                    {shouldRenderCancelShiftTrigger && !!provider && (
                        <Grid item style={{ whiteSpace: 'nowrap' }}>
                            <CancelShiftModalTrigger
                                providerId={provider_id}
                                provider={provider}
                                slot={slot}
                                refresh={refresh}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
