import React, { useState } from 'react';
import theme from 'theme';
import { styled, useMediaQuery } from '@mui/material';

import { NavigationBar } from './NavigationBar';
import { Sidebar } from './Sidebar';
import { Tray } from './Tray';

export function Navigation({ children }: { children: React.ReactNode | React.ReactNode[] }) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState<boolean>(!small);
    const [value, setValue] = useState<number>(0);

    return (
        <>
            <NavigationBar small={small} open={open} setOpen={setOpen} />
            <TopSpacer small={small.toString()} />
            <Page id="sidebar-container">
                <Sidebar small={small} open={open} setOpen={setOpen} setValue={setValue} />
                <InnerPage open={open.toString()} small={small.toString()}>
                    <BoundsContainer>
                        {children}
                        {small && <BottomSpacer />}
                    </BoundsContainer>
                    {small && <Tray value={value} setValue={setValue} />}
                </InnerPage>
            </Page>
        </>
    );
}

const TopSpacer = styled('div')(({ small }: { small: string }) => ({
    height: small === 'true' ? 50 : 60,
}));

const BottomSpacer = styled('div')({
    height: 60,
    bottom: 0,
});

const Page = styled('div')({
    display: 'flex',
    width: '100vw',
});

const InnerPage = styled('div')(({ open, small }: { open: string; small: string }) => ({
    display: 'flex',
    left: open === 'true' && small !== 'true' ? 250 : 0,
    minHeight: `calc(100vh - ${small === 'true' ? 50 : 60}px)`,
    width: `calc(100vw - ${open === 'true' && small !== 'true' ? 265 : 0}px)`,
    position: 'absolute',
}));

const BoundsContainer = styled('div')({
    flex: 1,
    maxWidth: '100vw',
});
