import { useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import theme from 'theme';
import MobileNavigation from './MobileNavigation';
import { navItems } from './navigationItems';
import WebNavigation from './WebNavigation';
import { useUserContext } from 'UserContext';

const isInUrl = (location: string, path: string) => {
    const basePath = path.includes('?') ? path.substring(0, path.indexOf('?')) : path;
    return location.includes(basePath);
};

const NavigationBar = () => {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const [selectedPage, setSelectedPage] = useState<number | undefined>();

    const { user, role } = useUserContext();
    const navigationItems = navItems(role ?? '', user?.role ?? '');

    useEffect(() => {
        setSelectedPage(navigationItems.findIndex((navItem) => isInUrl(location.pathname, navItem.target)));
    }, [location]);

    return smDown ? (
        <MobileNavigation
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            navigationItems={navigationItems}
        />
    ) : (
        <WebNavigation
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            navigationItems={navigationItems}
        />
    );
};

export default NavigationBar;
