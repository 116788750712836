import React from 'react';
import theme from 'theme';
import { Text } from 'library';
import { styled, useMediaQuery } from '@mui/material';
import { useUserContext } from 'UserContext';

import { TeacherShowcase } from './components/TeacherShowcase';

export function Dashboard() {
    const { user } = useUserContext();
    const small = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Container>
            <Text variant="display" textStyle={{ width: '100%' }}>
                {user?.first_name ? `Hi ${user.first_name}!` : 'Hi!'}
            </Text>
            <TeacherShowcase small={small} />
        </Container>
    );
}

const Container = styled('div')({
    padding: 20,
    width: '100%',
    height: '100%',
});
