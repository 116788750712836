import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import TopNavigation from '../components/TopNavigation';
import { Navigation } from 'parent-portal/navigation/new/Navigation';
import BusinessJobRequestModal from 'parent-portal/forms/BusinessJobRequestModal';
import { FeatureFlag, useFeatureFlag } from './FeatureFlagContext';

export const Private = ({ component: Component, ...rest }) => {
    const { featureActive: active } = useFeatureFlag(FeatureFlag.CENTER_REDESIGN);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (localStorage.getItem('token') !== null) {
                    if (active && props.location?.pathname?.indexOf('adminperson') === -1) {
                        return (
                            <>
                                <Navigation>
                                    <BusinessJobRequestModal />
                                    <Component {...props} />
                                </Navigation>
                            </>
                        );
                    }
                    return (
                        <>
                            <TopNavigation />
                            <BusinessJobRequestModal />
                            <Component {...props} />
                        </>
                    );
                }

                const pathname = props.location?.pathname?.indexOf('sitter/') > -1 ? '/sitter' : '/';

                return <Redirect to={{ pathname, search: props.location.search, state: { from: props.location } }} />;
            }}
        />
    );
};
