import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Copy from '@mui/icons-material/CopyAll';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Colors, IconButton, Text } from 'library';
import DashboardSection from '../components/DashboardSection';
import { useUserContext } from 'UserContext';

export default function ReferralSection() {
    const { user } = useUserContext();
    const classes = useStyles();

    const refCode = user?.referral_code || user?.businesses_active?.[0].user.referral_code;

    const referralLink = `${window.location.origin}?referral=${refCode}`;

    return (
        <DashboardSection header="Refer a Center - Give $50, Get $50">
            <Grid>
                <Stepper alternativeLabel nonLinear>
                    <Step active>
                        <StepLabel classes={{ label: classes.label }} StepIconProps={{ style: { color: '#F490AC' } }}>
                            <Text bold>Share Your Link</Text>
                        </StepLabel>
                        <Text variant="caption" textStyle={{ textAlign: 'center', fontSize: 13 }}>
                            Share your custom sign up link with a center that may be interested in using Tandem to find
                            staff.
                        </Text>
                    </Step>
                    <Step active>
                        <StepLabel
                            classes={{ label: classes.label }}
                            StepIconProps={{ style: { color: Colors.mediumTurq } }}
                        >
                            <Text bold>Referral Signs Up, Give $50</Text>
                        </StepLabel>
                        <Text variant="caption" textStyle={{ textAlign: 'center', fontSize: 13 }}>
                            Your referral signs up and posts their first job on the Tandem site.
                        </Text>
                    </Step>
                    <Step active>
                        <StepLabel
                            classes={{ label: classes.label }}
                            StepIconProps={{ style: { color: Colors.mediumTerraCotta } }}
                        >
                            <Text bold>First Worker Booked, Get $50</Text>
                        </StepLabel>
                        <Text variant="caption" textStyle={{ textAlign: 'center', fontSize: 13 }}>
                            Once your referral posts a job and uses their first worker,{' '}
                            <u>you will both get $50 added to your Tandem account.</u>
                        </Text>
                    </Step>
                </Stepper>
                <Text variant="caption" textStyle={{ fontSize: 13, marginTop: 20 }}>
                    <b>Share your referral link:</b> {referralLink}{' '}
                    <IconButton
                        onClick={() => navigator.clipboard.writeText(referralLink)}
                        icon={Copy}
                        backgroundColor={Colors.darkNavy}
                        color={Colors.white}
                        style={{ marginLeft: 5 }}
                    />
                </Text>
            </Grid>
        </DashboardSection>
    );
}

const useStyles = makeStyles(() => ({
    label: {
        color: `${Colors.darkNavy} !important`,
        fontFamily: 'Work Sans !important',
    },
}));
