import React, { Fragment } from 'react';
import { Avatar, SwipeableDrawer, styled } from '@mui/material';
import { Colors, Text } from 'library';
import { useClient } from 'shared';
import { useHistory } from 'react-router';

import {
    AttachMoney,
    EventAvailable,
    Help,
    Home,
    ManageAccounts,
    Message,
    People,
    Store,
    WatchLater,
} from '@mui/icons-material';

interface MenuItem {
    title: string;
    icon: JSX.Element;
    route: string;
}

interface CenterContact {
    first_name: string;
    role: string;
    phone_number: string;
    email: string;
    profile_picture: string;
}

interface SharedNavigation {
    value: number;
    label: string;
}

export function Sidebar({
    small,
    open,
    setOpen,
    setValue,
}: {
    small: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
    setValue: (value: number) => void;
}) {
    const history = useHistory();
    const { data: contact, loading, error } = useClient<CenterContact>({ url: 'api/dashboard/center-contact/' });

    const SHARED_NAVIGATION_ITEMS: SharedNavigation[] = [
        { label: 'Home', value: 0 },
        { label: 'Payments', value: 1 },
        { label: 'My Jobs', value: 2 },
        { label: 'Timesheets', value: 3 },
        { label: 'My Staff', value: 4 },
    ];

    const TOP_MENU: MenuItem[] = [
        { title: 'Home', icon: <Home htmlColor={Colors.darkNavy} />, route: '/home' },
        { title: 'My Jobs', icon: <EventAvailable htmlColor={Colors.darkNavy} />, route: '/jobs' },
        { title: 'My Staff', icon: <People htmlColor={Colors.darkNavy} />, route: '/providers' },
        { title: 'Timesheets', icon: <WatchLater htmlColor={Colors.darkNavy} />, route: '/home' },
        { title: 'Payments', icon: <AttachMoney htmlColor={Colors.darkNavy} />, route: '/payments' },
        { title: 'Messages', icon: <Message htmlColor={Colors.darkNavy} />, route: '/messages' },
    ];

    const BOTTOM_MENU: MenuItem[] = [
        { title: 'Manage Center', icon: <Store htmlColor={Colors.darkNavy} />, route: '/home' },
        { title: 'Manage Users', icon: <ManageAccounts htmlColor={Colors.darkNavy} />, route: '/home' },
    ];

    const LOWEST_MENU: MenuItem[] = [
        {
            title: 'FAQ',
            icon: <Help htmlColor={Colors.darkNavy} />,
            route: 'https://chatwoot.join-tandem.com/hc/child-care-center-help-center/en',
        },
    ];

    function onClick(item: MenuItem) {
        if (small) {
            setOpen(false);
        }

        const value = SHARED_NAVIGATION_ITEMS.find((nav) => nav.label === item.title)?.value;
        if (value !== undefined) {
            setValue(value);
        }

        item.route?.length && item.route[0] === '/' ? history.push(item.route) : (window.location.href = item.route);
    }

    return (
        <Fragment key="left">
            <StyledDrawer
                anchor="left"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                variant={small ? 'temporary' : 'persistent'}
                disableDiscovery
                disableBackdropTransition
                ModalProps={{
                    container: document.getElementById('sidebar-container'),
                }}
                PaperProps={{
                    style: {
                        ...{
                            top: small ? 50 : 0,
                        },
                        ...(small ? { height: 'calc(100vh - 50px)' } : { height: 'calc(100vh - 60px)', top: 60 }),
                    },
                }}
            >
                <Section>
                    {TOP_MENU.map((item: MenuItem) => (
                        <MenuOption key={item.title} onClick={() => onClick(item)}>
                            <MenuOptionIcon>{item.icon}</MenuOptionIcon>
                            <Text variant="h2">{item.title}</Text>
                        </MenuOption>
                    ))}
                    <Spacer />
                    {BOTTOM_MENU.map((item: MenuItem) => (
                        <MenuOption key={item.title} onClick={() => onClick(item)}>
                            <MenuOptionIcon>{item.icon}</MenuOptionIcon>
                            <Text variant="h2">{item.title}</Text>
                        </MenuOption>
                    ))}
                </Section>
                <Section>
                    {LOWEST_MENU.map((item: MenuItem) => (
                        <MenuOption key={item.title} onClick={() => onClick(item)}>
                            <MenuOptionIcon>{item.icon}</MenuOptionIcon>
                            <Text variant="h2">{item.title}</Text>
                        </MenuOption>
                    ))}
                    <Contact>
                        <Text variant="h2" bold>
                            Tandem Contact Info
                        </Text>
                        {loading || !!error ? (
                            <Text variant="caption" textStyle={{ paddingTop: 10 }}>
                                Need help? Reach out to us at{' '}
                                <a
                                    style={{ color: Colors.darkNavy, textDecorationLine: 'underline' }}
                                    href="mailto:support@join-tandem.com"
                                >
                                    support@join-tandem.com
                                </a>{' '}
                                to get in touch with our support team.
                            </Text>
                        ) : (
                            <ContactSection>
                                <AvatarContainer>
                                    <AdminAvatar src={contact?.profile_picture} />
                                </AvatarContainer>
                                <ContactText>
                                    <Text bold>{contact?.first_name}</Text>
                                    <Text variant="caption">{contact?.role}</Text>
                                    <Text textStyle={{ marginTop: 5, overflowWrap: 'break-word' }} variant="body2">
                                        {contact?.phone_number}
                                    </Text>
                                    <Text textStyle={{ marginTop: 2, overflowWrap: 'break-word' }} variant="body2">
                                        {contact?.email}
                                    </Text>
                                </ContactText>
                            </ContactSection>
                        )}
                    </Contact>
                </Section>
            </StyledDrawer>
        </Fragment>
    );
}

const StyledDrawer = styled(SwipeableDrawer)({
    '& .MuiDrawer-paper': {
        alignItems: 'center',
        backgroundColor: Colors.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 10,
        width: 250,
    },
});

const Section = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const MenuOption = styled('div')({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    padding: '10px',
    width: '100%',
    backgroundColor: Colors.white,
    '&:hover': {
        backgroundColor: Colors.lightGrey,
    },
    '&:focus': {
        backgroundColor: Colors.lightGrey,
    },
    '&:active': {
        backgroundColor: Colors.lightGrey,
    },
});

const MenuOptionIcon = styled('div')({
    paddingLeft: 10,
    paddingRight: 10,
});

const Spacer = styled('div')({
    height: 1,
    backgroundColor: Colors.lightGrey,
    marginBottom: 20,
    marginTop: 20,
    width: '80%',
});

const Contact = styled('div')({
    backgroundColor: Colors.lightTurq,
    padding: 10,
    width: '100%',
});

const ContactSection = styled('div')({
    alignItems: 'flex-start',
    display: 'flex',
    overflow: 'wrap',
});

const AvatarContainer = styled('div')({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '30%',
});

const AdminAvatar = styled(Avatar)({
    aspectRatio: 1,
    color: Colors.white,
    backgroundColor: Colors.darkNavy,
    borderRadius: '50%',
    marginTop: 10,
    marginBottom: 10,
    height: '100%',
    width: '100%',
});

const ContactText = styled('div')({
    padding: 10,
    width: '70%',
});
