import React from 'react';
import { Colors, Text } from 'library';
import { BottomNavigation, BottomNavigationAction, Grid, styled } from '@mui/material';
import { AttachMoney, EventAvailable, Home, People, WatchLater } from '@mui/icons-material';
import { useHistory } from 'react-router';

interface NavigationItem {
    name: string;
    icon: React.ReactNode;
    target: string;
    id: string;
}

export function Tray({ value, setValue }: { value: number; setValue: (value: number) => void }) {
    const history = useHistory();

    const NAVIGATION_ITEMS: NavigationItem[] = [
        { name: 'Home', icon: <Home />, target: '/home', id: 'bottom-bar-home' },
        { name: 'Payments', icon: <AttachMoney />, target: '/payments', id: 'bottom-bar-payments' },
        { name: 'My Jobs', icon: <EventAvailable />, target: '/jobs', id: 'bottom-my-jobs' },
        { name: 'Timesheets', icon: <WatchLater />, target: '/messages', id: 'bottom-bar-messages' },
        { name: 'My Staff', icon: <People />, target: '/providers', id: 'bottom-bar-providers' },
    ];

    return (
        <StyledDrawer>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                {NAVIGATION_ITEMS.map((item) => (
                    <StyledNavigationAction
                        key={item.id}
                        label={
                            <>
                                <Grid style={{ flexDirection: 'row' }}>
                                    <Text variant="caption">{item.name}</Text>
                                </Grid>
                            </>
                        }
                        icon={item.icon}
                        onClick={() => history.push(item.target)}
                    />
                ))}
            </BottomNavigation>
        </StyledDrawer>
    );
}

const StyledDrawer = styled('div')({
    backgroundColor: Colors.white,
    bottom: 0,
    boxShadow: '0px -3px 6px #00000029',
    padding: 4,
    position: 'fixed',
    width: '100vw',
    zIndex: 999,
});

const StyledNavigationAction = styled(BottomNavigationAction)({
    '& .Mui-selected': {
        fontFamily: 'Work Sans',
        fontSize: 10,
    },
    '& .MuiBottomNavigationAction-root': {
        fontFamily: 'Work Sans',
        fontSize: 10,
    },
    '& .MuiBottomNavigationAction-label': {
        color: Colors.darkNavy,
        fontFamily: 'Work Sans',
        fontSize: 10,
    },
    '&.Mui-selected': {
        color: Colors.darkNavy,
        outline: 'none',
        borderBottom: '2px solid',
        borderBottomColor: Colors.darkNavy,
        fontFamily: 'Work Sans',
        fontSize: 10,
    },
});
