import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Colors } from 'library';
import { useUserContext } from 'UserContext';
import { FeatureFlag, client, consoleLogInDev, useFeatureFlag } from 'shared';
import { IDashboardData } from './Models/DashboardData';
import BusinessDashboard from './BusinessDashboard';
import FamilyLetter from './FamilyLetter';
import { Dashboard } from './new/Dashboard';

const HomeDashboard = () => {
    const { role } = useUserContext();
    const [dashboardData, setDashboardData] = useState<IDashboardData>();

    const getData = () => {
        client(`api/dashboard/portal/`)
            .then((response: IDashboardData) => {
                setDashboardData(response);
            })
            .catch(consoleLogInDev);
    };

    const { featureActive: active } = useFeatureFlag(FeatureFlag.CENTER_REDESIGN);

    useEffect(getData, []);

    if (role === 'business_active') {
        if (active) {
            return <Dashboard />;
        }
        return <BusinessDashboard data={dashboardData} updateData={getData} />;
    }

    return (
        <div style={{ backgroundColor: Colors.white, height: '100%' }}>
            <Grid container direction="row" style={{ paddingTop: '2%', paddingLeft: 50, paddingRight: 50 }}>
                <FamilyLetter />
            </Grid>
        </div>
    );
};

export default HomeDashboard;
