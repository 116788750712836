import React from 'react';
import { Avatar, Card, Rating, styled } from '@mui/material';
import { Checkbox, Colors, Text } from 'library';
import { School, StarRounded, WorkRounded } from '@mui/icons-material';

import type { ITeacher } from './TeacherShowcase';

export function TeacherCard({
    small,
    worker,
    selected,
    onChange,
}: {
    small: boolean;
    worker: ITeacher;
    selected: boolean;
    onChange: (choice: ITeacher) => void;
}) {
    return (
        <StyledCard small={small.toString()}>
            <MainColumn style={{ height: '45%' }}>
                <Header>
                    <Row>
                        <SideSection justify="center">
                            <Pill>
                                <Text color={Colors.terraCotta} variant="body2">
                                    SHOWCASE
                                </Text>
                            </Pill>
                        </SideSection>
                        <MiddleSection>
                            <WorkerAvatar src={worker.pictureUrl} />
                        </MiddleSection>
                        <SideSection justify="flex-end">
                            <Checkbox
                                label=""
                                labelTitle="Select this teacher"
                                checked={selected}
                                onChange={() => onChange(worker)}
                                style={{ width: 'auto', marginRight: 10 }}
                                checkboxStyle={{ backgroundColor: Colors.white }}
                            />
                        </SideSection>
                    </Row>
                </Header>
                <Column>
                    <Text bold variant="h1" textStyle={{ textDecorationLine: 'underline' }}>
                        {worker.name}
                    </Text>
                    {worker.credentials.reviews?.average_score && worker.credentials.reviews.average_score > 3 ? (
                        <PaddedRow>
                            <Rating
                                name="read-only"
                                value={worker.credentials.reviews?.average_score}
                                readOnly
                                icon={<StarRounded style={{ height: 32, width: 32, color: '#ffe200' }} />}
                                precision={0.1}
                            />
                            <Text variant="body2" textStyle={{ paddingLeft: 5 }}>
                                ({worker.credentials.reviews.total_reviews})
                            </Text>
                        </PaddedRow>
                    ) : (
                        <PaddedRow>
                            <Text>No reviews yet!</Text>
                        </PaddedRow>
                    )}
                </Column>
            </MainColumn>
            <CredentialsContainer>
                <Credential>
                    <IconCircle>
                        <School htmlColor={Colors.white} style={{ height: 24, width: 24 }} />
                    </IconCircle>
                    <Text color={Colors.navy}>{worker.credentials.education.join(', ')}</Text>
                </Credential>
                {worker.credentials.experience && (
                    <Credential>
                        <IconCircle>
                            <WorkRounded htmlColor={Colors.white} style={{ height: 24, width: 24 }} />
                        </IconCircle>
                        <Text color={Colors.navy}>{worker.credentials.experience ?? 'New to childcare'}</Text>
                    </Credential>
                )}
                {worker.credentials.highlights && (
                    <Credential>
                        <IconCircle>
                            <StarRounded htmlColor={Colors.white} style={{ height: 24, width: 24 }} />
                        </IconCircle>
                        <Text color={Colors.navy}>{worker.credentials.highlights.join(', ')}</Text>
                    </Credential>
                )}
            </CredentialsContainer>
        </StyledCard>
    );
}

const StyledCard = styled(Card)(({ small }: { small: string }) => ({
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: small === 'true' ? 0 : 20,
    marginBottom: small === 'true' ? 20 : 0,
    width: small === 'true' ? '100%' : '350px',
}));

const Header = styled('div')({
    background: `linear-gradient(180deg, ${Colors.terraCotta} calc(50% + 10px), transparent calc(50% + 10px))`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
});

const SideSection = styled('div')(({ justify }: { justify: string }) => ({
    alignItems: 'center',
    display: 'flex',
    height: 'calc(50% + 10px)',
    justifyContent: justify,
    width: '33%',
}));

const Pill = styled('div')({
    backgroundColor: Colors.white,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 8px',
});

const MiddleSection = styled('div')({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '34%',
});

const Row = styled('div')({
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
});

const PaddedRow = styled(Row)({
    alignItems: 'center',
    paddingTop: 10,
});

const WorkerAvatar = styled(Avatar)({
    color: Colors.white,
    backgroundColor: Colors.darkNavy,
    width: 100,
    height: 100,
    borderRadius: '50%',
    marginTop: 10,
    display: 'inline-flex',
});

const Column = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
});

const MainColumn = styled(Column)({
    height: '45%',
    marginBottom: 20,
});

const CredentialsContainer = styled('div')({
    backgroundColor: '#EFEFEF',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: 20,
    paddingLeft: 30,
    paddingTop: 10,
});

const Credential = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
});

const IconCircle = styled('div')({
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: Colors.mediumNavy,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
});
