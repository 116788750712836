import React, { useState } from 'react';
import { Colors, PrimaryButton, Text } from 'library';
import { Grid } from '@material-ui/core';
import { useModalsContext } from 'ModalsContext';
import moment from 'moment';
import { isBefore, isToday } from 'date-fns';
import { useFeatureFlag, FeatureFlag, consoleLogInDev, client } from 'shared';
import useMicrodepositVerificationInfo from 'parent-portal/home/paymentVerificationWarning/useMicrodepositVerficationInfo';
import MissingMicrodepositVerificationModal from 'parent-portal/home/paymentVerificationWarning/MissingMicrodepositVerificationModal';

import { AutoHireWorkerDialog } from './AutoHireWorker/AutoHireWorkerDialog';
import { useUserContext } from 'UserContext';
import PaymentStatusnModal from 'parent-portal/home/components/PaymentStatusModal';

export default function PostAShiftCard({
    d,
    addressId,
    businessLocationId,
    timezone,
}: {
    d: Date;
    addressId?: number;
    businessLocationId?: number;
    timezone?: string;
}) {
    const { user } = useUserContext();
    const { openJobForm } = useModalsContext();
    const { featureActive: active } = useFeatureFlag(FeatureFlag.AUTOSCHEDULE_WORKER);
    const [open, setOpen] = useState(false);
    const [missingOpen, setMissingOpen] = useState(false);
    const [showOverduePayments, setShowOverduePayments] = useState(false);
    const [overduePaymentStatus, setOverduePaymentStatus] = useState('');
    const { blockJobPostingOnVerification } = useMicrodepositVerificationInfo();

    function onAddWorkerClick() {
        const business = user?.businesses_active?.[0];
        if (business) {
            client(`api/business/${business.id}/`)
                .then((response) => {
                    if (response.payment_status === 'BLOCKED') {
                        setShowOverduePayments(true);
                        setOverduePaymentStatus(response.payment_status);
                    } else {
                        blockJobPostingOnVerification ? setMissingOpen(true) : setOpen(true);
                    }
                })
                .catch((error) => {
                    consoleLogInDev(error);
                    blockJobPostingOnVerification ? setMissingOpen(true) : setOpen(true);
                });
        } else {
            blockJobPostingOnVerification ? setMissingOpen(true) : setOpen(true);
        }
    }

    if (isBefore(d, new Date()) && !isToday(d)) {
        return <></>;
    }

    return (
        <Grid
            item
            key={d.toLocaleDateString() + 'job'}
            container
            direction="column"
            style={{ padding: '20px 20px 0px 20px' }}
            alignContent="center"
        >
            <Grid
                item
                container
                direction="column"
                style={{
                    borderRadius: 18,
                    backgroundColor: Colors.lightTurq,
                    padding: 10,
                    boxShadow: '0px 3px 6px 0px #00000029',
                }}
            >
                <div>
                    <Text variant="body2" textStyle={{ marginBottom: 10 }}>
                        Post a shift for {d.toLocaleDateString()}.
                    </Text>
                    <PrimaryButton
                        buttonStyle={{
                            width: 'unset',
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 2,
                            paddingBottom: 2,
                        }}
                        onClick={() =>
                            openJobForm(`Post a job card (Business Calendar)`, {
                                startDate: moment(d),
                                endDate: moment(d),
                                address: addressId ? [{ id: addressId, businessLocationId, timezone }] : undefined,
                            })
                        }
                        textStyle={{ fontSize: 13 }}
                    >
                        Post Shift
                    </PrimaryButton>
                </div>
                {active && (
                    <div>
                        <Text variant="caption" textStyle={{ marginTop: 20 }} bold>
                            OR
                        </Text>
                        <Text variant="body2" textStyle={{ marginBottom: 10 }}>
                            Automatically add a previous worker to the schedule.
                            <b>
                                <u> They must have already agreed to the shift.</u>
                            </b>
                        </Text>
                        <PrimaryButton
                            buttonStyle={{
                                width: 'unset',
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 2,
                                paddingBottom: 2,
                            }}
                            textStyle={{ fontSize: 13 }}
                            onClick={onAddWorkerClick}
                        >
                            Add Worker
                        </PrimaryButton>
                        <AutoHireWorkerDialog open={open} setOpen={setOpen} addressId={addressId} defaultDay={d} />
                    </div>
                )}
                <MissingMicrodepositVerificationModal isOpen={missingOpen} onClose={() => setMissingOpen(false)} />
                <PaymentStatusnModal
                    isOpen={showOverduePayments}
                    onClose={() => {
                        setShowOverduePayments(false);
                        setOverduePaymentStatus('');
                    }}
                    paymentStatus={overduePaymentStatus}
                />
            </Grid>
        </Grid>
    );
}
